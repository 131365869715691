import { useEffect, useState } from "react";
import Tracking from "./components/Tracking";
import { useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

function App() {
  const [trackingData, setTrackingData] = useState({});
  const [trackingInput, setTrackingInput] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const fetchData = async (awbValue) => {
    setErrorMsg(""); // Clear previous error
    try {
      const response = await fetch(`${apiUrl}/tracking/${awbValue}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const result = await response.json();
      if (result?.data) {
        setTrackingData(result.data);
      } else {
        throw new Error("No data found for the provided AWB.");
      }
    } catch (error) {
      console.error("Error fetching tracking data:", error?.message || error);
      setErrorMsg(error?.message || "Something went wrong. Please try again.");
      setTrackingData({});
    }
  };

  const handleTrack = () => {
    if (trackingInput.trim()) {
      fetchData(trackingInput);
      navigate(`?awb=${trackingInput}`);
    } else {
      setErrorMsg("Please enter a valid AWB.");
    }
  };

  useEffect(() => {
    const handleURLChange = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const awbValue = searchParams.get("awb");

      if (awbValue) {
        setTrackingInput(awbValue);
        fetchData(awbValue);
      } else {
        setTrackingData({});
        setTrackingInput("");
        setErrorMsg(""); // Clear any previous error
      }
    };

    // Handle initial load
    handleURLChange();

    // Handle back/forward button
    window.addEventListener("popstate", handleURLChange);

    return () => {
      window.removeEventListener("popstate", handleURLChange);
    };
  }, []);

  return (
    <div>
      <Tracking
        trackingData={trackingData}
        handleTrack={handleTrack}
        trackingInput={trackingInput}
        setTrackingInput={setTrackingInput}
        errorMsg={errorMsg}
      />
    </div>
  );
}

export default App;
